import qs from 'qs';
import { QueryParamName } from '@/constants';

type Query = Record<string, any>;

class RouteBuilder {
  private store: string;
  private storeLang: string;

  constructor(storeLang: string) {
    const [store] = storeLang.split('-');
    this.store = store;
    this.storeLang = storeLang;
  }

  /**
   * dashboard-ui routes, support only store (/{store}/*) in URL
   */
  buildYourProfileRoute(): string {
    return `${location.origin}/${this.store}/your-profile`;
  }

  buildSubscriptionDetailsRoute({ subscriptionId }: { subscriptionId?: string } = {}): string {
    if (!subscriptionId) {
      return `/${this.store}/your-tech`;
    }
    return `/${this.store}/your-tech/${subscriptionId}`;
  }

  /**
   * payments-ui routes, support both store and language (/{store}-{lang}/*) in URL
   */
  buildPaymentMethodListRoute({ query }: { query?: Query } = {}): string {
    return `/${this.storeLang}/your-profile/payments/payment-methods${buildQueryString(query)}`;
  }

  buildAddPaymentMethodRoute({ query }: { query?: Query } = {}): string {
    return `/${this.storeLang}/your-profile/payments/payment-methods/add${buildQueryString(query)}`;
  }

  buildPaymentMethodDetailsRoute({
    billingAccountId,
    query,
  }: { billingAccountId?: string; query?: Query } = {}): string {
    return `/${
      this.storeLang
    }/your-profile/payments/payment-methods/${billingAccountId}${buildQueryString(query)}`;
  }

  buildPaymentMethodBulkUpdateRoute({
    paymentMethodId,
    query,
  }: {
    paymentMethodId: string;
    query?: Query;
  }): string {
    return `/${
      this.storeLang
    }/your-profile/payments/payment-methods/${paymentMethodId}/bulk${buildQueryString(query)}`;
  }

  buildChangePaymentMethodRoute({
    subscriptionId,
    query,
  }: {
    subscriptionId: string;
    query?: Query;
  }): string {
    const finalQuery = {
      ...query,
      [QueryParamName.subscriptionId]: subscriptionId,
    };

    return `/${this.storeLang}/your-profile/payments/payment-methods/change${buildQueryString(
      finalQuery
    )}`;
  }

  buildFilterPaymentsRoute({ query }: { query?: Query } = {}): string {
    return `/${this.storeLang}/your-payments${buildQueryString(query)}`;
  }
}

const buildQueryString = (query?: Query): string => {
  if (!query) return '';
  const querystring = qs.stringify(query, { encode: false });
  return querystring ? `?${querystring}` : '';
};

export default RouteBuilder;
