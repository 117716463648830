export const CURRENCIES_ASSOCIATION: Record<string, string> = {
  EUR: '€',
  USD: '$',
  GBP: '£',
};

export const DEFAULT_STORE_CODE = 'de';
export const DEFAULT_LANGUAGE = 'de';

export enum QueryParamName {
  subscriptionId = 'subscription-id',
  paymentMethodId = 'pm-id',
  paymentMethodStatus = 'p-status',
  bulkId = 'bulk-id',
  mode = 'mode',
  from = 'from',
  retry = 'retry',
}

export const GROVER_STORES = [
  'at',
  'de',
  'es',
  'nl',
  'us',
  'business_at',
  'business_nl',
  'business_es',
  'business_us',
  'business',
];

export const GROVER_LANGUAGES = ['de', 'en', 'nl', 'es'];