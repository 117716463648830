import { GROVER_STORES, GROVER_LANGUAGES, DEFAULT_LANGUAGE, DEFAULT_STORE_CODE } from '@/constants';

export const isValidLocalePattern = (input?: string): boolean => {
  if (!input) return false;
  if (!input.includes('-')) return false;

  const [store, lang] = input.split('-');

  if (!GROVER_STORES.includes(store) || !GROVER_LANGUAGES.includes(lang)) {
    return false;
  }

  return true;
};

export const getValidLocale = ({
  invalidStoreLang,
  languageFromCookie,
}: {
  invalidStoreLang?: string;
  languageFromCookie?: string;
}): string => {
  const langInCookieValid = languageFromCookie && GROVER_LANGUAGES.includes(languageFromCookie);
  const fallbackLanguage = langInCookieValid ? languageFromCookie : DEFAULT_LANGUAGE;

  if (!invalidStoreLang) {
    return `${DEFAULT_STORE_CODE}-${fallbackLanguage}`;
  }

  // maybe storeLang is just storeCode (i.e. legacy link)
  const isStoreOnly = GROVER_STORES.includes(invalidStoreLang);
  if (isStoreOnly) {
    return `${invalidStoreLang}-${fallbackLanguage}`;
  }

  // maybe at least store or language is valid
  if (invalidStoreLang.includes('-')) {
    const [store, lang] = invalidStoreLang.split('-');

    const isStoreValid = GROVER_STORES.includes(store);
    if (isStoreValid) {
      return `${store}-${fallbackLanguage}`;
    }

    const isLangValid = GROVER_LANGUAGES.includes(lang);
    if (isLangValid) {
      return `${DEFAULT_STORE_CODE}-${lang}`;
    }
  }

  return `${DEFAULT_STORE_CODE}-${fallbackLanguage}`;
};
