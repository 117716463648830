import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header } from './styles';
import { Text } from '@/components/Text';
import { Offset } from '@/components/Offset';
import { NavigationBar } from '@/components/NavigationBar';
import { PageContainer } from '@/components/PageContainer';
import { useApplicationData } from '@/providers/applicationData';
import { getLocale } from '@/utils/cookies';
import { Page } from '@/components/Page';
import { tk } from '@/i18n/translationKeys';
import { PaymentMethodList } from './PaymentMethodList';
import { SonntagButtonV2 } from '@getgrover/ui';
import {
  trackYourProfilePageVisitedEvent,
  trackPaymentMethodListPageAddButtonClickEvent,
  trackPaymentMethodListYourProfileLinkClickEvent,
} from '@/analytics';
import { PlusIcon } from '@/icons/PlusIcon';
import { DocumentTitle, PageName } from '@/components/DocumentTitle';
import { useTheme } from 'styled-components';
import { DEFAULT_LANGUAGE } from '@/constants';

export const PaymentMethodListPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId, storeCode, activeStore, routeBuilder } = useApplicationData();
  const theme = useTheme();
  
  useEffect(() => {
    const locale = getLocale() || DEFAULT_LANGUAGE;

    trackYourProfilePageVisitedEvent({
      path: location.pathname,
      store: activeStore.code,
      store_id: activeStore.store_id,
      locale,
    });
  }, []);

  const handleAddButtonClick = () => {
    trackPaymentMethodListPageAddButtonClickEvent({
      userId,
      store: storeCode,
    });

    navigate(routeBuilder.buildAddPaymentMethodRoute());
  };

  const sendNavigationBarClickAnalyticsEvent = () => {
    trackPaymentMethodListYourProfileLinkClickEvent({
      userId,
      store: storeCode,
    });
  };

  return (
    <Page>
      <DocumentTitle pageName={PageName.paymentMethodList} />
      <PageContainer>
        <NavigationBar
          url={routeBuilder.buildYourProfileRoute()}
          external={true}
          onClick={sendNavigationBarClickAnalyticsEvent}
          text={t(tk.yourProfileLinkText)}
        />
        <Header>
          <Text
            typography="Subheadline"
            color={theme.colors.text.darkerGrey}
            data-testid="payment-method-list-title"
          >
            {t(tk.paymentMethodListPageTitle)}
          </Text>
          <Text
            typography="Paragraph"
            color={theme.colors.text.darkGrey}
            data-testid="payment-method-list-subtitle"
          >
            {t(tk.paymentMethodListPageDescription)}
          </Text>
        </Header>
        <PaymentMethodList />

        <Offset top={4}>
          <SonntagButtonV2 size="small" iconSvgCode={PlusIcon} onClick={handleAddButtonClick}>
            {t(tk.paymentMethodListPageAddButtonText)}
          </SonntagButtonV2>
        </Offset>
      </PageContainer>
    </Page>
  );
};
