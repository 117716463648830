import { Outlet, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ApplicationDataProvider, ApplicationDataContextType } from '@/providers/applicationData';
import { getLocale, setLocale, setUserId, setUserType, setStore } from '@/utils/cookies';
import { Page500Light } from '@/components/ErrorPages';
import { WebViewConnector } from '@/modules/webView';
import { ApplicationLaunchResult, logger, setDatadogUser } from '@/logger';
import { LocalDevAuthButton } from '@/components/LocalDevAuthButton';
import { loadBrowserEnv } from '@/config';
import { fetchFlagsAndMap } from '@/flags/fetchAndMap';
import { UserType } from '@/generated/types';
import yourPaymentsPreloaderHtml from '../ssrPreloaders/your-payments-preloader.html?raw';
import changePaymentMethodPreloaderHtml from '../ssrPreloaders/change-payment-method-preloader.html?raw';
import paymentMethodListPreloaderHtml from '../ssrPreloaders/payment-method-list-preloader.html?raw';
import { getActiveStore } from '@/axiosRequest';
import { getUserProfile } from '@/gql/queries';
import { getPlatform, getRawUseragent } from '@/utils/useragent';
import { useTranslation } from 'react-i18next';
import { enrichAnalyticsUser } from '@devsbb/analytics-client';
import RouteBuilder from './routeBuilders';
import { appVersion } from '@/version';
import { getValidLocale, isValidLocalePattern } from './utils';

const browserEnv = loadBrowserEnv();
const { LOCAL_AUTH_EMAIL, LOCAL_AUTH_PASSWORD, NODE_ENV, LOCAL_AUTH_AUTO_LOGIN } = browserEnv;
const localAutoLoginEnabled =
  LOCAL_AUTH_EMAIL &&
  LOCAL_AUTH_PASSWORD &&
  LOCAL_AUTH_AUTO_LOGIN === 'true' &&
  NODE_ENV === 'development';

export const ApplicationWrapper = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isGroverApp = Boolean(window?.ReactNativeWebView);
  const { storeLang } = useParams<{ storeLang: string }>();
  const [applicationData, setApplicationData] = useState<ApplicationDataContextType | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const prepareApplicationData = async () => {
      if (!storeLang || !isValidLocalePattern(storeLang)) {
        const validStoreLang = getValidLocale({ invalidStoreLang: storeLang, languageFromCookie: getLocale() });
        const newPath = location.pathname.replace(`/${storeLang}`, `/${validStoreLang}`);
        const newUrl = `${newPath}${location.search}`;

        logger.info('Redirect to valid store-language', {
          invalidStore: storeLang,
          storeRedirect: `${storeLang} to ${validStoreLang}`,
        });

        return navigate(newUrl, { replace: true });
      }

      const [store, language] = storeLang.split('-');
      
      let activeStore;

      try {
        activeStore = await getActiveStore(store as string);
      } catch (e) {
        logger.info('getActiveStore error', { store });
        setError(e);
        setLoading(false);
        return;
      }

      try {
        const profile = await getUserProfile();

        const user = profile || {};
        const isConsolidatedBilling = false; //user?.groupBilling?.enabled;
        const billingDay = 0; // user?.groupBilling?.chosenBillingDay;

        setLocale(language); // for backward compatibility with dashboard-ui
        i18n.changeLanguage(language);

        const { flags, analyticsFlags } = await fetchFlagsAndMap({
          userId: Number(user.id),
          storeCode: activeStore.code,
          userType: user.type as UserType,
        });

        const rawUseragent = getRawUseragent();
        const platform = getPlatform();

        const routeBuilder = new RouteBuilder(storeLang);

        setApplicationData({
          userId: user.id as string,
          storeCode: activeStore.code,
          countryCode: activeStore.country_code,
          activeStore,
          user,
          isConsolidatedBilling,
          billingDay,
          flags,
          rawUseragent,
          platform,
          routeBuilder,
        });

        if (user.id) {
          setUserId(user.id);
        }

        if (user.type) {
          setUserType(user.type);
        }

        if (activeStore?.code) {
          setStore(activeStore?.code);
        }

        try {
          await enrichAnalyticsUser({
            id: user.id as string,
            store_code: activeStore?.code,
            consolidated_billing: !!isConsolidatedBilling,
            featureFlags: analyticsFlags,
          });
        } catch (error) {
          logger.error('Cannot enrich analytics user', { error, userId: user.id });
        }

        await setDatadogUser({
          id: user.id as string,
          platform,
          rawUseragent,
          storeCode: activeStore?.code,
          featureFlags: flags,
          locale: getLocale(),
          version: appVersion,
        });

        logger.info('Application Launched', {
          applicationLaunchResult: ApplicationLaunchResult.Success,
          flavour: isGroverApp ? 'app' : 'web',
          store: activeStore.code,
          platform,
          rawUseragent,
          locale: getLocale(),
          version: appVersion,
        });
        setLoading(false);
      } catch (e: unknown) {
        const UNATHORIZED_ERROR_MESSAGE = 'Unauthorized: missing refresh token';
        const errorMessage = (e as any).message;

        if (errorMessage === UNATHORIZED_ERROR_MESSAGE) {
          logger.error('Application Launched', {
            applicationLaunchResult: ApplicationLaunchResult.Auth,
          });
          setAuthError(true);
        } else {
          logger.error('Application Launched', {
            applicationLaunchResult: ApplicationLaunchResult.Error,
            message: errorMessage,
          });
          setError(e);
          setLoading(false);
        }
      }
    };

    prepareApplicationData();
  }, [storeLang]);

  if (authError) {
    if (localAutoLoginEnabled) {
      return <div>Error! {localAutoLoginEnabled ? <LocalDevAuthButton /> : null}</div>;
    }

    return null;
  }

  if (error) {
    logger.error('Light Error', {
      name: (error as any).name,
      message: (error as any).message,
      code: (error as any).code,
    });
    return <Page500Light />;
  }

  // Preloaders TBD
  // should not render Routes (Outlet) before user, flags and store are provided
  if (loading) {
    if (location.pathname.endsWith('your-payments')) {
      return <div dangerouslySetInnerHTML={{ __html: yourPaymentsPreloaderHtml }} />;
    }

    if (location.href.includes('your-profile/payments/payment-methods/change?subscription-id')) {
      return <div dangerouslySetInnerHTML={{ __html: changePaymentMethodPreloaderHtml }} />;
    }

    if (location.pathname.endsWith('your-profile/payments/payment-methods')) {
      return <div dangerouslySetInnerHTML={{ __html: paymentMethodListPreloaderHtml }} />;
    }

    return null;
  }

  return (
    <ApplicationDataProvider value={applicationData}>
      <WebViewConnector />
      <Outlet />
    </ApplicationDataProvider>
  );
};
