import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { YourPaymentsPage } from '@/pages/YourPaymentsPage';
import { PaymentMethodListPage } from '@/pages/PaymentMethodListPage';
import { PaymentMethodDetailsPage } from '@/pages/PaymentMethodDetailsPage';
import { AddPaymentMethodPage } from '@/pages/AddPaymentMethodPage';
import { ChangePaymentMethodPage } from '@/pages/ChangePaymentMethodPage';
import { NotFound } from '@/components/ErrorPages';
import { ApplicationWrapper } from '@/routes/ApplicationWrapper';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:storeLang" element={<ApplicationWrapper />}>
          <Route path="your-payments" element={<YourPaymentsPage />} />

          <Route path="your-profile/payments/payment-methods" element={<PaymentMethodListPage />} />

          <Route
            path="your-profile/payments/payment-methods/add"
            element={<AddPaymentMethodPage />}
          />

          <Route
            path="your-profile/payments/payment-methods/:paymentMethodId"
            element={<PaymentMethodDetailsPage />}
          />

          <Route
            path="your-profile/payments/payment-methods/:paymentMethodId/bulk"
            element={<ChangePaymentMethodPage />}
          />

          <Route
            path="your-profile/payments/payment-methods/change"
            element={<ChangePaymentMethodPage />}
          />
        </Route>

        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
